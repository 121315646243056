import { Injectable } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import { Quiz } from '../types/index';


@Injectable({
  providedIn: 'root'
})
export class QuizGatewayService {

  constructor() { }

  async addQuiz(quiz: Quiz){
    const apiName = 'er4QuizStaging';
    const path = '/additem';
    quiz.parsed_date()
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: quiz
    };

    API.post(apiName, path, myInit);
  }

  async publishItem(itemid): Promise<any>{
    const apiName = 'er4QuizStaging';
    const path = '/publishitem';    
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    API.post(apiName, path, myInit)
  }

  async unpublishItem(itemid): Promise<any>{
    const apiName = 'er4QuizStaging';
    const path = '/unpublishitem';
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    API.post(apiName, path, myInit)
  }

  async removeItem(itemid): Promise<any>{
    const apiName = 'er4QuizStaging';
    const path = '/removeitem';
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };
    await API.post(apiName, path, myInit);
  }

  async getItem(itemid): Promise<any>{
    const apiName = 'er4QuizStaging';
    const path = '/getitem';
    let returnvalue: Quiz;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }
}
