import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Auth } from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string;
  response;
  token;
  constructor() { }

  async isLoggedIn(): Promise<boolean>{
    await Auth.currentAuthenticatedUser().then(() => {
      this.response = new BehaviorSubject<boolean>(false);
    });
    return this.response;
  }

  async getAuthToken(){
    await Auth.currentSession().then((session) => {
      this.token = session.getIdToken().getJwtToken();
    });
    return this.token;
  }
}
