import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MaterialModule} from './material-module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import Amplify from 'aws-amplify';
import { LoginComponent } from './login/login.component';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { RouteGuardService } from './services/route-guard.service';
import { AuthService } from './services/auth.service';
import { FormsModule }   from '@angular/forms';
import { Quiz10Component } from './quiz10/quiz10.component';
import { Quiz20Component } from './quiz20/quiz20.component';

Amplify.configure({
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_6ol49U1Zh',
    userPoolWebClientId: 'fs1k699euq096i9at4k0ed59m'
    },
  API: {
    endpoints: [
        {
          name: 'er4QuizStaging',
          endpoint: 'https://ao5wwkxu0a.execute-api.ap-southeast-2.amazonaws.com/prod',
        }
      ]
  }
});
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    Quiz10Component,
    Quiz20Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    AmplifyUIAngularModule,
    FormsModule
  ],
  providers: [RouteGuardService,AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
