<div class="container">
  <h1>Quiz 20</h1>
  <form (ngSubmit)="getItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input
            type="text"
            class="form-control"
            id="itemid"
            required
            [(ngModel)]="modelid.itemid"
            name="itemid"
          />
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Get Item</button>
  </form>
  <br />
  <br />
  <br />
  <form (ngSubmit)="submitForm()" #quizFrom="ngForm">
    <div class="columns">
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input
            type="text"
            class="form-control"
            id="itemid"
            required
            [(ngModel)]="model.itemid"
            name="itemid"
          />
        </div>
      </div>

      <div class="column is-3">
        <div class="form-group">
          <label class="label labelstyle">Quiz Name</label>
          <input
            type="text"
            class="form-control"
            id="quizname"
            required
            [(ngModel)]="model.quizname"
            name="quizname"
          />
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Set Id</label>
          <select matNativeControl required class="form-control" [(ngModel)]="model.setid" name="setid">
            <option value="1">Level 1</option>
            <option value="2">Level 2</option>
            <option value="3">Level 3</option>
            <option value="4">Level 4</option>
          </select>        
        </div>
      </div>
      <div>
        <br/>
        <br/>
        <button type="button"  (click)="model.parsed_date()" class="standardButton btn btn-success">Set Date</button>
      </div>
    </div>

    <div>
      <h3>Question 1</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[0].name"
          name="question0"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera0"
              required
              [(ngModel)]="model.questions[0].options[0].name"
              name="answera0"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta0"
              required
              [(ngModel)]="model.questions[0].options[0].isAnswer"
              name="correcta0"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb0"
              required
              [(ngModel)]="model.questions[0].options[1].name"
              name="answerb0"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb0"
              required
              [(ngModel)]="model.questions[0].options[1].isAnswer"
              name="correctb0"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc0"
              required
              [(ngModel)]="model.questions[0].options[2].name"
              name="answerc0"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc0"
              required
              [(ngModel)]="model.questions[0].options[2].isAnswer"
              name="correctc0"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd0"
              required
              [(ngModel)]="model.questions[0].options[3].name"
              name="answerd0"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd0"
              required
              [(ngModel)]="model.questions[0].options[3].isAnswer"
              name="correctd0"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 2</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[1].name"
          name="question1"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera1"
              required
              [(ngModel)]="model.questions[1].options[0].name"
              name="answera1"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta1"
              required
              [(ngModel)]="model.questions[1].options[0].isAnswer"
              name="correcta1"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb1"
              required
              [(ngModel)]="model.questions[1].options[1].name"
              name="answerb1"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb1"
              required
              [(ngModel)]="model.questions[1].options[1].isAnswer"
              name="correctb1"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc1"
              required
              [(ngModel)]="model.questions[1].options[2].name"
              name="answerc1"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc1"
              required
              [(ngModel)]="model.questions[1].options[2].isAnswer"
              name="correctc1"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd1"
              required
              [(ngModel)]="model.questions[1].options[3].name"
              name="answerd1"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd1"
              required
              [(ngModel)]="model.questions[1].options[3].isAnswer"
              name="correctd1"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 3</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[2].name"
          name="question2"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera2"
              required
              [(ngModel)]="model.questions[2].options[0].name"
              name="answera2"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta2"
              required
              [(ngModel)]="model.questions[2].options[0].isAnswer"
              name="correcta2"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb2"
              required
              [(ngModel)]="model.questions[2].options[1].name"
              name="answerb2"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb2"
              required
              [(ngModel)]="model.questions[2].options[1].isAnswer"
              name="correctb2"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc2"
              required
              [(ngModel)]="model.questions[2].options[2].name"
              name="answerc2"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc2"
              required
              [(ngModel)]="model.questions[2].options[2].isAnswer"
              name="correctc2"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd2"
              required
              [(ngModel)]="model.questions[2].options[3].name"
              name="answerd2"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd2"
              required
              [(ngModel)]="model.questions[2].options[3].isAnswer"
              name="correctd2"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 4</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[3].name"
          name="question3"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera3"
              required
              [(ngModel)]="model.questions[3].options[0].name"
              name="answera3"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta3"
              required
              [(ngModel)]="model.questions[3].options[0].isAnswer"
              name="correcta3"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb3"
              required
              [(ngModel)]="model.questions[3].options[1].name"
              name="answerb3"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb3"
              required
              [(ngModel)]="model.questions[3].options[1].isAnswer"
              name="correctb3"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc3"
              required
              [(ngModel)]="model.questions[3].options[2].name"
              name="answerc3"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc3"
              required
              [(ngModel)]="model.questions[3].options[2].isAnswer"
              name="correctc3"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd3"
              required
              [(ngModel)]="model.questions[3].options[3].name"
              name="answerd3"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd3"
              required
              [(ngModel)]="model.questions[3].options[3].isAnswer"
              name="correctd3"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 5</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[4].name"
          name="question4"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera4"
              required
              [(ngModel)]="model.questions[4].options[0].name"
              name="answera4"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta4"
              required
              [(ngModel)]="model.questions[4].options[0].isAnswer"
              name="correcta4"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb4"
              required
              [(ngModel)]="model.questions[4].options[1].name"
              name="answerb4"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb4"
              required
              [(ngModel)]="model.questions[4].options[1].isAnswer"
              name="correctb4"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc4"
              required
              [(ngModel)]="model.questions[4].options[2].name"
              name="answerc4"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc4"
              required
              [(ngModel)]="model.questions[4].options[2].isAnswer"
              name="correctc4"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd4"
              required
              [(ngModel)]="model.questions[4].options[3].name"
              name="answerd4"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd4"
              required
              [(ngModel)]="model.questions[4].options[3].isAnswer"
              name="correctd4"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 6</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[5].name"
          name="question5"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera5"
              required
              [(ngModel)]="model.questions[5].options[0].name"
              name="answera5"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta5"
              required
              [(ngModel)]="model.questions[5].options[0].isAnswer"
              name="correcta5"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb5"
              required
              [(ngModel)]="model.questions[5].options[1].name"
              name="answerb5"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb5"
              required
              [(ngModel)]="model.questions[5].options[1].isAnswer"
              name="correctb5"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc5"
              required
              [(ngModel)]="model.questions[5].options[2].name"
              name="answerc5"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc5"
              required
              [(ngModel)]="model.questions[5].options[2].isAnswer"
              name="correctc5"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd5"
              required
              [(ngModel)]="model.questions[5].options[3].name"
              name="answerd5"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd5"
              required
              [(ngModel)]="model.questions[5].options[3].isAnswer"
              name="correctd5"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 7</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[6].name"
          name="question6"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera6"
              required
              [(ngModel)]="model.questions[6].options[0].name"
              name="answera6"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta6"
              required
              [(ngModel)]="model.questions[6].options[0].isAnswer"
              name="correcta6"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb6"
              required
              [(ngModel)]="model.questions[6].options[1].name"
              name="answerb6"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb6"
              required
              [(ngModel)]="model.questions[6].options[1].isAnswer"
              name="correctb6"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc6"
              required
              [(ngModel)]="model.questions[6].options[2].name"
              name="answerc6"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc6"
              required
              [(ngModel)]="model.questions[6].options[2].isAnswer"
              name="correctc6"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd6"
              required
              [(ngModel)]="model.questions[6].options[3].name"
              name="answerd6"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd6"
              required
              [(ngModel)]="model.questions[6].options[3].isAnswer"
              name="correctd6"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 8</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[7].name"
          name="question7"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera7"
              required
              [(ngModel)]="model.questions[7].options[0].name"
              name="answera7"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta7"
              required
              [(ngModel)]="model.questions[7].options[0].isAnswer"
              name="correcta7"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb7"
              required
              [(ngModel)]="model.questions[7].options[1].name"
              name="answerb7"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb7"
              required
              [(ngModel)]="model.questions[7].options[1].isAnswer"
              name="correctb7"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc7"
              required
              [(ngModel)]="model.questions[7].options[2].name"
              name="answerc7"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc7"
              required
              [(ngModel)]="model.questions[7].options[2].isAnswer"
              name="correctc7"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd7"
              required
              [(ngModel)]="model.questions[7].options[3].name"
              name="answerd7"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd7"
              required
              [(ngModel)]="model.questions[7].options[3].isAnswer"
              name="correctd7"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 9</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[8].name"
          name="question8"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera8"
              required
              [(ngModel)]="model.questions[8].options[0].name"
              name="answera8"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta8"
              required
              [(ngModel)]="model.questions[8].options[0].isAnswer"
              name="correcta8"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb8"
              required
              [(ngModel)]="model.questions[8].options[1].name"
              name="answerb8"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb8"
              required
              [(ngModel)]="model.questions[8].options[1].isAnswer"
              name="correctb8"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc8"
              required
              [(ngModel)]="model.questions[8].options[2].name"
              name="answerc8"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc8"
              required
              [(ngModel)]="model.questions[8].options[2].isAnswer"
              name="correctc8"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd8"
              required
              [(ngModel)]="model.questions[8].options[3].name"
              name="answerd8"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd8"
              required
              [(ngModel)]="model.questions[8].options[3].isAnswer"
              name="correctd8"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 10</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[9].name"
          name="question9"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera9"
              required
              [(ngModel)]="model.questions[9].options[0].name"
              name="answera9"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta9"
              required
              [(ngModel)]="model.questions[9].options[0].isAnswer"
              name="correcta9"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb9"
              required
              [(ngModel)]="model.questions[9].options[1].name"
              name="answerb9"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb9"
              required
              [(ngModel)]="model.questions[9].options[1].isAnswer"
              name="correctb9"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc9"
              required
              [(ngModel)]="model.questions[9].options[2].name"
              name="answerc9"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc9"
              required
              [(ngModel)]="model.questions[9].options[2].isAnswer"
              name="correctc9"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd9"
              required
              [(ngModel)]="model.questions[9].options[3].name"
              name="answerd9"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd9"
              required
              [(ngModel)]="model.questions[9].options[3].isAnswer"
              name="correctd9"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 11</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[10].name"
          name="question10"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera10"
              required
              [(ngModel)]="model.questions[10].options[0].name"
              name="answera10"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta10"
              required
              [(ngModel)]="model.questions[10].options[0].isAnswer"
              name="correcta10"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb10"
              required
              [(ngModel)]="model.questions[10].options[1].name"
              name="answerb10"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb10"
              required
              [(ngModel)]="model.questions[10].options[1].isAnswer"
              name="correctb10"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc10"
              required
              [(ngModel)]="model.questions[10].options[2].name"
              name="answerc10"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc10"
              required
              [(ngModel)]="model.questions[10].options[2].isAnswer"
              name="correctc10"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd10"
              required
              [(ngModel)]="model.questions[10].options[3].name"
              name="answerd10"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd10"
              required
              [(ngModel)]="model.questions[10].options[3].isAnswer"
              name="correctd10"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 12</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[11].name"
          name="question11"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera11"
              required
              [(ngModel)]="model.questions[11].options[0].name"
              name="answera11"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta11"
              required
              [(ngModel)]="model.questions[11].options[0].isAnswer"
              name="correcta11"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb11"
              required
              [(ngModel)]="model.questions[11].options[1].name"
              name="answerb11"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb11"
              required
              [(ngModel)]="model.questions[11].options[1].isAnswer"
              name="correctb11"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc11"
              required
              [(ngModel)]="model.questions[11].options[2].name"
              name="answerc11"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc11"
              required
              [(ngModel)]="model.questions[11].options[2].isAnswer"
              name="correctc11"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd11"
              required
              [(ngModel)]="model.questions[11].options[3].name"
              name="answerd11"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd11"
              required
              [(ngModel)]="model.questions[11].options[3].isAnswer"
              name="correctd11"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 13</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[12].name"
          name="question12"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera12"
              required
              [(ngModel)]="model.questions[12].options[0].name"
              name="answera12"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta12"
              required
              [(ngModel)]="model.questions[12].options[0].isAnswer"
              name="correcta12"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb12"
              required
              [(ngModel)]="model.questions[12].options[1].name"
              name="answerb12"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb12"
              required
              [(ngModel)]="model.questions[12].options[1].isAnswer"
              name="correctb12"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc12"
              required
              [(ngModel)]="model.questions[12].options[2].name"
              name="answerc12"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc12"
              required
              [(ngModel)]="model.questions[12].options[2].isAnswer"
              name="correctc12"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd12"
              required
              [(ngModel)]="model.questions[12].options[3].name"
              name="answerd12"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd12"
              required
              [(ngModel)]="model.questions[12].options[3].isAnswer"
              name="correctd12"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 14</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[13].name"
          name="question13"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera13"
              required
              [(ngModel)]="model.questions[13].options[0].name"
              name="answera13"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta13"
              required
              [(ngModel)]="model.questions[13].options[0].isAnswer"
              name="correcta13"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb13"
              required
              [(ngModel)]="model.questions[13].options[1].name"
              name="answerb13"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb13"
              required
              [(ngModel)]="model.questions[13].options[1].isAnswer"
              name="correctb13"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc13"
              required
              [(ngModel)]="model.questions[13].options[2].name"
              name="answerc13"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc13"
              required
              [(ngModel)]="model.questions[13].options[2].isAnswer"
              name="correctc13"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd13"
              required
              [(ngModel)]="model.questions[13].options[3].name"
              name="answerd13"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd13"
              required
              [(ngModel)]="model.questions[13].options[3].isAnswer"
              name="correctd13"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 15</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[14].name"
          name="question14"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera14"
              required
              [(ngModel)]="model.questions[14].options[0].name"
              name="answera14"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta14"
              required
              [(ngModel)]="model.questions[14].options[0].isAnswer"
              name="correcta14"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb14"
              required
              [(ngModel)]="model.questions[14].options[1].name"
              name="answerb14"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb14"
              required
              [(ngModel)]="model.questions[14].options[1].isAnswer"
              name="correctb14"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc14"
              required
              [(ngModel)]="model.questions[14].options[2].name"
              name="answerc14"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc14"
              required
              [(ngModel)]="model.questions[14].options[2].isAnswer"
              name="correctc14"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd14"
              required
              [(ngModel)]="model.questions[14].options[3].name"
              name="answerd14"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd14"
              required
              [(ngModel)]="model.questions[14].options[3].isAnswer"
              name="correctd14"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 16</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[15].name"
          name="question15"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera15"
              required
              [(ngModel)]="model.questions[15].options[0].name"
              name="answera15"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta15"
              required
              [(ngModel)]="model.questions[15].options[0].isAnswer"
              name="correcta15"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb15"
              required
              [(ngModel)]="model.questions[15].options[1].name"
              name="answerb15"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb15"
              required
              [(ngModel)]="model.questions[15].options[1].isAnswer"
              name="correctb15"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc15"
              required
              [(ngModel)]="model.questions[15].options[2].name"
              name="answerc15"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc15"
              required
              [(ngModel)]="model.questions[15].options[2].isAnswer"
              name="correctc15"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd15"
              required
              [(ngModel)]="model.questions[15].options[3].name"
              name="answerd15"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd15"
              required
              [(ngModel)]="model.questions[15].options[3].isAnswer"
              name="correctd15"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 17</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[16].name"
          name="question16"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera16"
              required
              [(ngModel)]="model.questions[16].options[0].name"
              name="answera16"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta16"
              required
              [(ngModel)]="model.questions[16].options[0].isAnswer"
              name="correcta16"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb16"
              required
              [(ngModel)]="model.questions[16].options[1].name"
              name="answerb16"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb16"
              required
              [(ngModel)]="model.questions[16].options[1].isAnswer"
              name="correctb16"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc16"
              required
              [(ngModel)]="model.questions[16].options[2].name"
              name="answerc16"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc16"
              required
              [(ngModel)]="model.questions[16].options[2].isAnswer"
              name="correctc16"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd16"
              required
              [(ngModel)]="model.questions[16].options[3].name"
              name="answerd16"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd16"
              required
              [(ngModel)]="model.questions[16].options[3].isAnswer"
              name="correctd16"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 18</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[17].name"
          name="question17"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera17"
              required
              [(ngModel)]="model.questions[17].options[0].name"
              name="answera17"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta17"
              required
              [(ngModel)]="model.questions[17].options[0].isAnswer"
              name="correcta17"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb17"
              required
              [(ngModel)]="model.questions[17].options[1].name"
              name="answerb17"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb17"
              required
              [(ngModel)]="model.questions[17].options[1].isAnswer"
              name="correctb17"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc17"
              required
              [(ngModel)]="model.questions[17].options[2].name"
              name="answerc17"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc17"
              required
              [(ngModel)]="model.questions[17].options[2].isAnswer"
              name="correctc17"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd17"
              required
              [(ngModel)]="model.questions[17].options[3].name"
              name="answerd17"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd17"
              required
              [(ngModel)]="model.questions[17].options[3].isAnswer"
              name="correctd17"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 19</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[18].name"
          name="question18"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera18"
              required
              [(ngModel)]="model.questions[18].options[0].name"
              name="answera18"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta18"
              required
              [(ngModel)]="model.questions[18].options[0].isAnswer"
              name="correcta18"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb18"
              required
              [(ngModel)]="model.questions[18].options[1].name"
              name="answerb18"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb18"
              required
              [(ngModel)]="model.questions[18].options[1].isAnswer"
              name="correctb18"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc18"
              required
              [(ngModel)]="model.questions[18].options[2].name"
              name="answerc18"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc18"
              required
              [(ngModel)]="model.questions[18].options[2].isAnswer"
              name="correctc18"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd18"
              required
              [(ngModel)]="model.questions[18].options[3].name"
              name="answerd18"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd18"
              required
              [(ngModel)]="model.questions[18].options[3].isAnswer"
              name="correctd18"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <h3>Question 20</h3>
      <div class="form-group">
        <textarea
          type="text"
          class="form-control questionbox"
          id="question"
          required
          [(ngModel)]="model.questions[19].name"
          name="question19"
        ></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input
              type="text"
              class="form-control"
              id="answera19"
              required
              [(ngModel)]="model.questions[19].options[0].name"
              name="answera19"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correcta19"
              required
              [(ngModel)]="model.questions[19].options[0].isAnswer"
              name="correcta19"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input
              type="text"
              class="form-control"
              id="answerb19"
              required
              [(ngModel)]="model.questions[19].options[1].name"
              name="answerb19"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctb19"
              required
              [(ngModel)]="model.questions[19].options[1].isAnswer"
              name="correctb19"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input
              type="text"
              class="form-control"
              id="answerc19"
              required
              [(ngModel)]="model.questions[19].options[2].name"
              name="answerc19"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctc19"
              required
              [(ngModel)]="model.questions[19].options[2].isAnswer"
              name="correctc19"
            />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input
              type="text"
              class="form-control"
              id="answerd19"
              required
              [(ngModel)]="model.questions[19].options[3].name"
              name="answerd19"
            />
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input
              type="checkbox"
              class="form-control"
              id="correctd19"
              required
              [(ngModel)]="model.questions[19].options[3].isAnswer"
              name="correctd19"
            />
          </div>
        </div>
      </div>
    </div>

    <button type="submit" class="btn btn-success">Submit</button>
  </form>
  <br />
  <br />
  <br />
  <form (ngSubmit)="publishItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input
            type="text"
            class="form-control"
            id="itemid"
            required
            [(ngModel)]="quizchangestate.itemid"
            name="itemid"
          />
        </div>
      </div>
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Set Id</label>
          <select
            matNativeControl
            required
            class="form-control"
            [(ngModel)]="quizchangestate.setid"
            name="setid"
          >
            <option value="1">Level 1</option>
            <option value="2">Level 2</option>
            <option value="3">Level 3</option>
            <option value="4">Level 4</option>
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Publish Item</button>
  </form>
  <br />
  <br />
  <br />
  <form (ngSubmit)="unpublishItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input
            type="text"
            class="form-control"
            id="itemid"
            required
            [(ngModel)]="quizchangestate.itemid"
            name="itemid"
          />
        </div>
      </div>
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Set Id</label>
          <select
            matNativeControl
            required
            class="form-control"
            [(ngModel)]="quizchangestate.setid"
            name="setid"
          >
            <option value="1">Level 1</option>
            <option value="2">Level 2</option>
            <option value="3">Level 3</option>
            <option value="4">Level 4</option>
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Unpublish Item</button>
  </form>
  <br />
  <br />
  <br />
  <form (ngSubmit)="removeItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input
            type="text"
            class="form-control"
            id="itemid"
            required
            [(ngModel)]="quizchangestate.itemid"
            name="itemid"
          />
        </div>
      </div>
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Set Id</label>
          <select
            matNativeControl
            required
            class="form-control"
            [(ngModel)]="quizchangestate.setid"
            name="setid"
          >
            <option value="1">Level 1</option>
            <option value="2">Level 2</option>
            <option value="3">Level 3</option>
            <option value="4">Level 4</option>
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Remove Item</button>
  </form>
  <br />
  <br />
  <br />
</div>
