import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class RouteGuardService implements CanActivate{
  isLoggedIn = false;
  redirectUrl: string;
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    this.redirectUrl = state.url;
    return this.checkLogin(this.redirectUrl);
  }

  checkLogin(url: string): boolean{
    if (this.isLoggedIn){
      return true;
    }else{
      this.authService.isLoggedIn().then(
        res => {
          this.isLoggedIn = true;
          this.router.navigateByUrl(url);
        },
        err => {
          this.isLoggedIn = false;
          this.authService.redirectUrl = url;
          this.router.navigateByUrl('/login');
        }
      );
    }
  }
}
