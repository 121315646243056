<div class="container">
  <h1>Quiz 10</h1>
  <form (ngSubmit)="getItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="modelid.itemid" name="itemid">
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Get Item</button>
  </form>
  <br/>
  <br/>
  <br/>
  <form (ngSubmit)="submitForm()" #quizFrom="ngForm">
    <div class="columns">
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="model.itemid" name="itemid">
        </div>
      </div>

      <div class="column is-3">
        <div class="form-group">
          <label class="label labelstyle">Quiz Name</label>
          <input type="text" class="form-control" id="quizname" required [(ngModel)]="model.quizname" name="quizname">
        </div>
      </div>
    </div>


    <div class="columns">
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Set Id</label>
          <select matNativeControl required class="form-control" [(ngModel)]="model.setid" name="setid">
            <option value="1">Level 1</option>
            <option value="2">Level 2</option>
            <option value="3">Level 3</option>
            <option value="4">Level 4</option>
            <option value="5">Level 5</option>
            <option value="6">Level 6</option>
            <option value="7">Level 7</option>
            <option value="8">Level 8</option>
            <option value="9">Level 9</option>
            <option value="10">Level 10</option>
            <option value="11">Level 11</option>
            <option value="12">Level 12</option>          
            <option value="13">Level 13</option>
            <option value="14">Level 14</option>
            <option value="15">Level 15</option>
            <option value="16">Level 16</option>
            <option value="17">Level 17</option>
            <option value="18">Level 18</option>
            <option value="19">Level 19</option>
            <option value="20">Level 20</option>          
          </select>        
        </div>
      </div>
      <div>
        <br/>
        <br/>
        <button type="button"  (click)="model.parsed_date()" class="standardButton btn btn-success">Set Date</button>
      </div>
    </div>
    <div>
      <h3>Question 1</h3>
      <div class="form-group">
        <textarea type="text" class="form-control questionbox" id="question" required [(ngModel)]="model.questions[0].name" name="question0"></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input type="text" class="form-control" id="answera0" required [(ngModel)]="model.questions[0].options[0].name" name="answera0">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correcta0" required [(ngModel)]="model.questions[0].options[0].isAnswer" name="correcta0">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input type="text" class="form-control" id="answerb0" required [(ngModel)]="model.questions[0].options[1].name" name="answerb0">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctb0" required [(ngModel)]="model.questions[0].options[1].isAnswer" name="correctb0">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input type="text" class="form-control" id="answerc0" required [(ngModel)]="model.questions[0].options[2].name" name="answerc0">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctc0" required [(ngModel)]="model.questions[0].options[2].isAnswer" name="correctc0">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input type="text" class="form-control" id="answerd0" required [(ngModel)]="model.questions[0].options[3].name" name="answerd0">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctd0" required [(ngModel)]="model.questions[0].options[3].isAnswer" name="correctd0">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Check answer</label>
            <input type="text" class="form-control" id="checklink0" required [(ngModel)]="model.questions[0].checklink" name="checklink0"/>
          </div>
        </div>        
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Explanation</label>
            <textarea type="text" class="form-control questionbox" id="explanation0" required [(ngModel)]="model.questions[0].explanation" name="explanation0"></textarea>
          </div>
        </div>        
      </div>
    </div>

    <div>
      <h3>Question 2</h3>
      <div class="form-group">
        <textarea type="text" class="form-control questionbox" id="question" required [(ngModel)]="model.questions[1].name" name="question1"></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input type="text" class="form-control" id="answera1" required [(ngModel)]="model.questions[1].options[0].name" name="answera1">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correcta1" required [(ngModel)]="model.questions[1].options[0].isAnswer" name="correcta1">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input type="text" class="form-control" id="answerb1" required [(ngModel)]="model.questions[1].options[1].name" name="answerb1">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctb1" required [(ngModel)]="model.questions[1].options[1].isAnswer" name="correctb1">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input type="text" class="form-control" id="answerc1" required [(ngModel)]="model.questions[1].options[2].name" name="answerc1">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctc1" required [(ngModel)]="model.questions[1].options[2].isAnswer" name="correctc1">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input type="text" class="form-control" id="answerd1" required [(ngModel)]="model.questions[1].options[3].name" name="answerd1">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctd1" required [(ngModel)]="model.questions[1].options[3].isAnswer" name="correctd1">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Check answer</label>
            <input type="text" class="form-control" id="checklink1" required [(ngModel)]="model.questions[1].checklink" name="checklink1"/>
          </div>
        </div>        
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Explanation</label>
            <textarea type="text" class="form-control questionbox" id="explanation1" required [(ngModel)]="model.questions[1].explanation" name="explanation1"></textarea>
          </div>
        </div>        
      </div>
    </div>

    <div>
      <h3>Question 3</h3>
      <div class="form-group">
        <textarea type="text" class="form-control questionbox" id="question" required [(ngModel)]="model.questions[2].name" name="question2"></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input type="text" class="form-control" id="answera2" required [(ngModel)]="model.questions[2].options[0].name" name="answera2">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correcta2" required [(ngModel)]="model.questions[2].options[0].isAnswer" name="correcta2">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input type="text" class="form-control" id="answerb2" required [(ngModel)]="model.questions[2].options[1].name" name="answerb2">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctb2" required [(ngModel)]="model.questions[2].options[1].isAnswer" name="correctb2">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input type="text" class="form-control" id="answerc2" required [(ngModel)]="model.questions[2].options[2].name" name="answerc2">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctc2" required [(ngModel)]="model.questions[2].options[2].isAnswer" name="correctc2">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input type="text" class="form-control" id="answerd2" required [(ngModel)]="model.questions[2].options[3].name" name="answerd2">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctd2" required [(ngModel)]="model.questions[2].options[3].isAnswer" name="correctd2">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Check answer</label>
            <input type="text" class="form-control" id="checklink2" required [(ngModel)]="model.questions[2].checklink" name="checklink2"/>
          </div>
        </div>        
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Explanation</label>
            <textarea type="text" class="form-control questionbox" id="explanation2" required [(ngModel)]="model.questions[2].explanation" name="explanation2"></textarea>
          </div>
        </div>        
      </div>
    </div>

    <div>
      <h3>Question 4</h3>
      <div class="form-group">
        <textarea type="text" class="form-control questionbox" id="question" required [(ngModel)]="model.questions[3].name" name="question3"></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input type="text" class="form-control" id="answera3" required [(ngModel)]="model.questions[3].options[0].name" name="answera3">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correcta3" required [(ngModel)]="model.questions[3].options[0].isAnswer" name="correcta3">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input type="text" class="form-control" id="answerb3" required [(ngModel)]="model.questions[3].options[1].name" name="answerb3">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctb3" required [(ngModel)]="model.questions[3].options[1].isAnswer" name="correctb3">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input type="text" class="form-control" id="answerc3" required [(ngModel)]="model.questions[3].options[2].name" name="answerc3">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctc3" required [(ngModel)]="model.questions[3].options[2].isAnswer" name="correctc3">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input type="text" class="form-control" id="answerd3" required [(ngModel)]="model.questions[3].options[3].name" name="answerd3">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctd3" required [(ngModel)]="model.questions[3].options[3].isAnswer" name="correctd3">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Check answer</label>
            <input type="text" class="form-control" id="checklink3" required [(ngModel)]="model.questions[3].checklink" name="checklink3"/>
          </div>
        </div>        
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Explanation</label>
            <textarea type="text" class="form-control questionbox" id="explanation3" required [(ngModel)]="model.questions[3].explanation" name="explanation3"></textarea>
          </div>
        </div>        
      </div>
    </div>

    <div>
      <h3>Question 5</h3>
      <div class="form-group">
        <textarea type="text" class="form-control questionbox" id="question" required [(ngModel)]="model.questions[4].name" name="question4"></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input type="text" class="form-control" id="answera4" required [(ngModel)]="model.questions[4].options[0].name" name="answera4">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correcta4" required [(ngModel)]="model.questions[4].options[0].isAnswer" name="correcta4">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input type="text" class="form-control" id="answerb4" required [(ngModel)]="model.questions[4].options[1].name" name="answerb4">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctb4" required [(ngModel)]="model.questions[4].options[1].isAnswer" name="correctb4">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input type="text" class="form-control" id="answerc4" required [(ngModel)]="model.questions[4].options[2].name" name="answerc4">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctc4" required [(ngModel)]="model.questions[4].options[2].isAnswer" name="correctc4">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input type="text" class="form-control" id="answerd4" required [(ngModel)]="model.questions[4].options[3].name" name="answerd4">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctd4" required [(ngModel)]="model.questions[4].options[3].isAnswer" name="correctd4">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Check answer</label>
            <input type="text" class="form-control" id="checklink4" required [(ngModel)]="model.questions[4].checklink" name="checklink4"/>
          </div>
        </div>        
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Explanation</label>
            <textarea type="text" class="form-control questionbox" id="explanation4" required [(ngModel)]="model.questions[4].explanation" name="explanation4"></textarea>
          </div>
        </div>        
      </div>
    </div>

    <div>
      <h3>Question 6</h3>
      <div class="form-group">
        <textarea type="text" class="form-control questionbox" id="question" required [(ngModel)]="model.questions[5].name" name="question5"></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input type="text" class="form-control" id="answera5" required [(ngModel)]="model.questions[5].options[0].name" name="answera5">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correcta5" required [(ngModel)]="model.questions[5].options[0].isAnswer" name="correcta5">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input type="text" class="form-control" id="answerb5" required [(ngModel)]="model.questions[5].options[1].name" name="answerb5">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctb5" required [(ngModel)]="model.questions[5].options[1].isAnswer" name="correctb5">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input type="text" class="form-control" id="answerc5" required [(ngModel)]="model.questions[5].options[2].name" name="answerc5">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctc5" required [(ngModel)]="model.questions[5].options[2].isAnswer" name="correctc5">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input type="text" class="form-control" id="answerd5" required [(ngModel)]="model.questions[5].options[3].name" name="answerd5">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctd5" required [(ngModel)]="model.questions[5].options[3].isAnswer" name="correctd5">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Check answer</label>
            <input type="text" class="form-control" id="checklink5" required [(ngModel)]="model.questions[5].checklink" name="checklink5"/>
          </div>
        </div>        
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Explanation</label>
            <textarea type="text" class="form-control questionbox" id="explanation5" required [(ngModel)]="model.questions[5].explanation" name="explanation5"></textarea>
          </div>
        </div>        
      </div>
    </div>

    <div>
      <h3>Question 7</h3>
      <div class="form-group">
        <textarea type="text" class="form-control questionbox" id="question" required [(ngModel)]="model.questions[6].name" name="question6"></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input type="text" class="form-control" id="answera6" required [(ngModel)]="model.questions[6].options[0].name" name="answera6">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correcta6" required [(ngModel)]="model.questions[6].options[0].isAnswer" name="correcta6">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input type="text" class="form-control" id="answerb6" required [(ngModel)]="model.questions[6].options[1].name" name="answerb6">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctb6" required [(ngModel)]="model.questions[6].options[1].isAnswer" name="correctb6">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input type="text" class="form-control" id="answerc6" required [(ngModel)]="model.questions[6].options[2].name" name="answerc6">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctc6" required [(ngModel)]="model.questions[6].options[2].isAnswer" name="correctc6">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input type="text" class="form-control" id="answerd6" required [(ngModel)]="model.questions[6].options[3].name" name="answerd6">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctd6" required [(ngModel)]="model.questions[6].options[3].isAnswer" name="correctd6">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Check answer</label>
            <input type="text" class="form-control" id="checklink6" required [(ngModel)]="model.questions[6].checklink" name="checklink6"/>
          </div>
        </div>        
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Explanation</label>
            <textarea type="text" class="form-control questionbox" id="explanation6" required [(ngModel)]="model.questions[6].explanation" name="explanation6"></textarea>
          </div>
        </div>        
      </div>
    </div>

    <div>
      <h3>Question 8</h3>
      <div class="form-group">
        <textarea type="text" class="form-control questionbox" id="question" required [(ngModel)]="model.questions[7].name" name="question7"></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input type="text" class="form-control" id="answera7" required [(ngModel)]="model.questions[7].options[0].name" name="answera7">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correcta7" required [(ngModel)]="model.questions[7].options[0].isAnswer" name="correcta7">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input type="text" class="form-control" id="answerb7" required [(ngModel)]="model.questions[7].options[1].name" name="answerb7">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctb7" required [(ngModel)]="model.questions[7].options[1].isAnswer" name="correctb7">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input type="text" class="form-control" id="answerc7" required [(ngModel)]="model.questions[7].options[2].name" name="answerc7">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctc7" required [(ngModel)]="model.questions[7].options[2].isAnswer" name="correctc7">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input type="text" class="form-control" id="answerd7" required [(ngModel)]="model.questions[7].options[3].name" name="answerd7">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctd7" required [(ngModel)]="model.questions[7].options[3].isAnswer" name="correctd7">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Check answer</label>
            <input type="text" class="form-control" id="checklink7" required [(ngModel)]="model.questions[7].checklink" name="checklink7"/>
          </div>
        </div>        
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Explanation</label>
            <textarea type="text" class="form-control questionbox" id="explanation7" required [(ngModel)]="model.questions[7].explanation" name="explanation7"></textarea>
          </div>
        </div>        
      </div>
    </div>

    <div>
      <h3>Question 9</h3>
      <div class="form-group">
        <textarea type="text" class="form-control questionbox" id="question" required [(ngModel)]="model.questions[8].name" name="question8"></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input type="text" class="form-control" id="answera8" required [(ngModel)]="model.questions[8].options[0].name" name="answera8">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correcta8" required [(ngModel)]="model.questions[8].options[0].isAnswer" name="correcta8">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input type="text" class="form-control" id="answerb8" required [(ngModel)]="model.questions[8].options[1].name" name="answerb8">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctb8" required [(ngModel)]="model.questions[8].options[1].isAnswer" name="correctb8">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input type="text" class="form-control" id="answerc8" required [(ngModel)]="model.questions[8].options[2].name" name="answerc8">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctc8" required [(ngModel)]="model.questions[8].options[2].isAnswer" name="correctc8">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input type="text" class="form-control" id="answerd8" required [(ngModel)]="model.questions[8].options[3].name" name="answerd8">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctd8" required [(ngModel)]="model.questions[8].options[3].isAnswer" name="correctd8">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Check answer</label>
            <input type="text" class="form-control" id="checklink8" required [(ngModel)]="model.questions[8].checklink" name="checklink8"/>
          </div>
        </div>        
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Explanation</label>
            <textarea type="text" class="form-control questionbox" id="explanation8" required [(ngModel)]="model.questions[8].explanation" name="explanation8"></textarea>
          </div>
        </div>        
      </div>
    </div>

    <div>
      <h3>Question 10</h3>
      <div class="form-group">
        <textarea type="text" class="form-control questionbox" id="question" required [(ngModel)]="model.questions[9].name" name="question9"></textarea>
      </div>
    </div>
    <div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer A</label>
            <input type="text" class="form-control" id="answera9" required [(ngModel)]="model.questions[9].options[0].name" name="answera9">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correcta9" required [(ngModel)]="model.questions[9].options[0].isAnswer" name="correcta9">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer B</label>
            <input type="text" class="form-control" id="answerb9" required [(ngModel)]="model.questions[9].options[1].name" name="answerb9">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctb9" required [(ngModel)]="model.questions[9].options[1].isAnswer" name="correctb9">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer C</label>
            <input type="text" class="form-control" id="answerc9" required [(ngModel)]="model.questions[9].options[2].name" name="answerc9">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctc9" required [(ngModel)]="model.questions[9].options[2].isAnswer" name="correctc9">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <div class="form-group">
            <label class="label labelstyle">Answer D</label>
            <input type="text" class="form-control" id="answerd9" required [(ngModel)]="model.questions[9].options[3].name" name="answerd9">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Correct</label>
            <input type="checkbox" class="form-control" id="correctd9" required [(ngModel)]="model.questions[9].options[3].isAnswer" name="correctd9">
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Check answer</label>
            <input type="text" class="form-control" id="checklink9" required [(ngModel)]="model.questions[9].checklink" name="checklink9"/>
          </div>
        </div>        
      </div>
      <div class="columns">
        <div class="column is-9">
          <div class="form-group">
            <label class="label labelstyle">Explanation</label>
            <textarea type="text" class="form-control questionbox" id="explanation9" required [(ngModel)]="model.questions[9].explanation" name="explanation9"></textarea>
          </div>
        </div>        
      </div>
    </div>

    <button type="submit" class="btn btn-success">Submit</button>
  </form>
  <br/>
  <br/>
  <br/>
  <form (ngSubmit)="publishItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="quizchangestate.itemid" name="itemid">
        </div>
      </div>
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Set Id</label>
          <select matNativeControl required class="form-control" [(ngModel)]="quizchangestate.setid" name="setid">
            <option value="1">Level 1</option>
            <option value="2">Level 2</option>
            <option value="3">Level 3</option>
            <option value="4">Level 4</option>
            <option value="5">Level 5</option>
            <option value="6">Level 6</option>
            <option value="7">Level 7</option>
            <option value="8">Level 8</option>
            <option value="9">Level 9</option>
            <option value="10">Level 10</option>
            <option value="11">Level 11</option>
            <option value="12">Level 12</option>          
            <option value="13">Level 13</option>
            <option value="14">Level 14</option>
            <option value="15">Level 15</option>
            <option value="16">Level 16</option>
            <option value="17">Level 17</option>
            <option value="18">Level 18</option>
            <option value="19">Level 19</option>
            <option value="20">Level 20</option>          
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Publish Item</button>
  </form>
  <br/>
  <br/>
  <br/>
  <form (ngSubmit)="unpublishItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="quizchangestate.itemid" name="itemid">
        </div>
      </div>
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Set Id</label>
          <select matNativeControl required class="form-control" [(ngModel)]="quizchangestate.setid" name="setid">
            <option value="1">Level 1</option>
            <option value="2">Level 2</option>
            <option value="3">Level 3</option>
            <option value="4">Level 4</option>
            <option value="5">Level 5</option>
            <option value="6">Level 6</option>
            <option value="7">Level 7</option>
            <option value="8">Level 8</option>
            <option value="9">Level 9</option>
            <option value="10">Level 10</option>
            <option value="11">Level 11</option>
            <option value="12">Level 12</option>          
            <option value="13">Level 13</option>
            <option value="14">Level 14</option>
            <option value="15">Level 15</option>
            <option value="16">Level 16</option>
            <option value="17">Level 17</option>
            <option value="18">Level 18</option>
            <option value="19">Level 19</option>
            <option value="20">Level 20</option>          
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Unpublish Item</button>
  </form>
  <br/>
  <br/>
  <br/>
  <form (ngSubmit)="removeItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="quizchangestate.itemid" name="itemid">
        </div>
      </div>
      <div class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Set Id</label>
          <select matNativeControl required class="form-control" [(ngModel)]="quizchangestate.setid" name="setid">
            <option value="1">Level 1</option>
            <option value="2">Level 2</option>
            <option value="3">Level 3</option>
            <option value="4">Level 4</option>
            <option value="5">Level 5</option>
            <option value="6">Level 6</option>
            <option value="7">Level 7</option>
            <option value="8">Level 8</option>
            <option value="9">Level 9</option>
            <option value="10">Level 10</option>
            <option value="11">Level 11</option>
            <option value="12">Level 12</option>          
            <option value="13">Level 13</option>
            <option value="14">Level 14</option>
            <option value="15">Level 15</option>
            <option value="16">Level 16</option>
            <option value="17">Level 17</option>
            <option value="18">Level 18</option>
            <option value="19">Level 19</option>
            <option value="20">Level 20</option>          
          </select>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Remove Item</button>
  </form>
  <br/>
  <br/>
  <br/>
</div>
