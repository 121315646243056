export class Option {
  id: number;
  questionId: number;
  name: string;
  isAnswer: boolean;
  selected: boolean;

  constructor(data: any) {
    if ( data){
      data = data || {};
      this.id = data.id;
      this.questionId = data.questionId;
      this.name = data.name;
      if(data.isAnswer){
      this.isAnswer = data.isAnswer;}
      else{
        this.isAnswer = false;
      }
      this.selected = false;
    }
  }
}
