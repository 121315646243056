import { Component, OnInit } from '@angular/core';
import { QuizGatewayService } from 'src/app/services/quiz-gateway.service';
import { Quiz, QuizItemId, QuizChangeState} from '../types/index';

@Component({
  selector: 'app-quiz10',
  templateUrl: './quiz10.component.html',
  styleUrls: ['./quiz10.component.scss']
})
export class Quiz10Component implements OnInit {
  model = new Quiz(null, 10);
  modelid = new QuizItemId();
  quizchangestate = new QuizChangeState();
  constructor(private quizGatewayService: QuizGatewayService) {}

  ngOnInit(): void {
  }

  submitForm(){
    this.quizGatewayService.addQuiz(this.model);
  }

  getItem() {
    this.quizGatewayService.getItem(this.modelid).then(response => {
      this.model = new Quiz(response, 10);
    });
  }

  removeItem(){
    this.quizGatewayService.removeItem(this.quizchangestate);
  }

  publishItem(){
    this.quizGatewayService.publishItem(this.quizchangestate);
  }

  unpublishItem(){
    this.quizGatewayService.unpublishItem(this.quizchangestate);
  }
}
