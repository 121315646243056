import { Component, OnInit } from '@angular/core';
import { FormFieldTypes } from '@aws-amplify/ui-components';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formFields: FormFieldTypes;
  constructor() {
    this.formFields = [
    {
      type: 'username',
      required: true,
    },
    {
      type: 'password',
      required: true,
    }
  ];
}

  ngOnInit(): void {
  }

}
