<section class="section">
    <div class="container">
      <div class="columns is-mobile is-centered">
        <div class="column is-full">
          <div class="tile is-ancestor">
            <div class="tile is-full is-parent">
              <article class="tile is-child smoke post">
                <h2 class="post__title">Quiz</h2>
                <div class="post__content">
                  <mat-grid-list cols="1" rowHeight="60px" style="padding: 20px; align-items: start" color="primary">
                    <mat-grid-tile
                        [colspan]="1"
                        [rowspan]="1"
                        [routerLink]="['/quiz10']"
                        [ngStyle]="{'font-size': '20px', 'cursor':'pointer','font-weight':'bold'}">
                        <div class="text-inside-grid">Quiz 10</div>
                      </mat-grid-tile>
                      <mat-grid-tile
                        [colspan]="1"
                        [rowspan]="1"
                        [routerLink]="['/quiz20']"
                        [ngStyle]="{'font-size': '20px', 'cursor':'pointer','font-weight':'bold'}">
                        <div class="text-inside-grid">Quiz 20</div>
                    </mat-grid-tile>
                  </mat-grid-list>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  