import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { Quiz10Component } from './quiz10/quiz10.component';
import { Quiz20Component } from './quiz20/quiz20.component';
import { RouteGuardService } from './services/route-guard.service';

const routes: Routes = [  {
  path: '',
  component: HomeComponent,
  canActivate: [RouteGuardService]
}, 
{
  path: 'quiz10',
  component: Quiz10Component,
  canActivate: [RouteGuardService]
},
{
  path: 'quiz20',
  component: Quiz20Component,
  canActivate: [RouteGuardService]
},
{
  path: 'login',
  component: LoginComponent ,    
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
